function initMap() {
  var map = new google.maps.Map(document.getElementById('map'), {
    scrollwheel: false,
    zoom: 17,
    center: {
      lat: 21.111806,
      lng: -101.710667
    }
  });

  var marker = new google.maps.Marker({
    position: {
      lat: 21.111806,
      lng: -101.710667
    },
    map: map,
  });
}
